<script>
import { VProgressCircular } from 'vuetify/lib'

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  name: 'LoaderHoc',
  render: function(createElement) {
    const defaultAttrs = {
      color: 'primary',
      indeterminate: true,
      size: 50,
      width: 2
    }
    const attrs = this.$attrs
    const content = this.model ? [
      createElement('div', {
        class: 'loader-hoc__inner d-flex align-center justify-center',
      }, [createElement(VProgressCircular, {
        attrs: {
          ...defaultAttrs,
          ...attrs
        }
      })])
    ] : this.$slots.default
    return createElement(
      'div',
      {
        class: 'loader-hoc'
      },
      content
    )
  },
  computed: {
    model: {
      get() {
        return this.value
      }
    }
  }
}
</script>
<style lang="scss">
.loader-hoc {

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>