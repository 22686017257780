<template>
  <div class="card d-flex flex-column">
    <v-card-title
      v-if="$slots.title" 
      class="title px-0 pb-6"
    >
      <slot name="title" />
    </v-card-title>
    <v-card
      v-bind="$attrs"
      class="flex-grow-1"
    >
      <v-subheader
        v-if="title"
      >
        {{ title }}
      </v-subheader>
      <slot />
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    title: {}
  },
  name: 'Card'
}
</script>
<style lang="scss">
.card {
  width: 100%;
}
</style>